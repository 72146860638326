<template>
    <div class="wrapper">
      <left-nav />
      <top-nav />
  
      <div class="content-wrapper">
        <jumbottron
          faIcon="fa-solid fa-database"
          PageTitle="Toutes nos dataBlocs"
        />
  
        <div class="content">
          <div class="container-fluid">

            <b-card class="card-primary card-outline">
              <div class="card-header">
                <h6 style="color: #3A77BC; font-weight: bold;">Zone de filtre</h6>
              </div>

              <div class="card-body">
                <b-row>
                    <div class="form-group" style="width: 45%">
                        <label>Date de début</label>
                        <input type="date" class="form-control" v-model="date1" @change="filterDataByDate">
                    </div>

                    <div class="form-group" style="width: 45%">
                        <label>Date de fin</label>
                        <input type="date" class="form-control" v-model="date2" @change="filterDataByDate">
                    </div>

                    <div class="form-group" style="width: 10%">
                        <label for="">Réinitialiser</label>
                        <b-button @click="reinializ()">
                            <i class="fa-solid fa-rotate-right"></i>
                        </b-button>
                    </div>
                </b-row>
              </div>
            </b-card>

            <div align="center" v-if="spinner">
              <b-spinner
                variant="primary"
                style="width: 3rem; height: 3rem"
                type="grow"
                label="Spinning"
              ></b-spinner>
            </div>
  
            <div  v-if="!spinner">
              <b-card class="card-primary card-outline">
                <div>
                  <b-row>
                    <!-- <b-col lg="6" class="my-1">
                      <b-form-group
                        label="Filtre"
                        label-for="sort-by-select"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="md"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-input-group size="md">
                          <b-form-select
                            id="sort-by-select"
                            v-model="sortBy"
                            :options="sortOptions"
                            :aria-describedby="ariaDescribedby"
                            class="w-75"
                          >
                            <template #first>
                              <option value="">-- Aucun --</option>
                            </template>
                          </b-form-select>
  
                          <b-form-select
                            v-model="sortDesc"
                            :disabled="!sortBy"
                            :aria-describedby="ariaDescribedby"
                            size="md"
                            class="w-25"
                          >
                            <option :value="false">Asc</option>
                            <option :value="true">Desc</option>
                          </b-form-select>
                        </b-input-group>
                      </b-form-group>
                    </b-col> -->
  
                    <!-- <b-col lg="6" class="my-1"> </b-col> -->
  
                    <b-col lg="6" class="my-1">
                      <b-form-group
                        label="Recherche"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="md"
                        class="mb-0"
                      >
                        <b-input-group size="md">
                          <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Chercher le titre par mot clé"
                          ></b-form-input>
  
                          <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''"
                              >Effacer</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
  
                    <b-col sm="5" md="6" class="my-1">
                      <b-form-group
                        label="Par page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="md"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="md"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
  
              <b-card class="card-primary card-outline">
                <b-row>
                  <b-col sm="5" md="6" class="my-1">
                    <strong>Total de dataBlocs: {{ totalRows }}</strong>
                  </b-col>
  
                  <b-col sm="7" md="6" class="my-1">
                    <div align="right">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0"
                      >
                      </b-pagination>
                    </div>
                  </b-col>
                </b-row>
  
                <b-table
                  :items="filteredData"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  no-border-collapse
                  class="mt-2 purple"
                  responsive
                >
                  <template #cell(title)="row">
                    <b>{{ row.value }}</b>
                  </template>
  
                  <template #cell(created_at)="date">
                    {{ date.value | formatDate }}
                  </template>
  
                  <template #cell(status)="status">
                    <b-badge v-if="status.value == 'innactiv'" pill style="background: linear-gradient(70deg, #3A77BC, #3FADE2);">
                      Inactif
                    </b-badge>
  
                    <b-badge v-if="status.value == 'activ'" pill style="background-color: #3BAE4C;">
                      Actif
                    </b-badge>
                  </template>
  
                  <template #cell(actions)="row">
                    <div class="btn-group">
                      <b-button
                        v-b-tooltip.hover
                        title="Supprimer la dataBloc"
                        @click="goToDeleteDataBloc(row.item)"
                        class=""
                        variant="danger"
                      >
                        <i class="fa-solid fa-trash"></i>
                      </b-button>
  
                      <b-button
                        v-b-tooltip.hover
                        title="Désactiver le dataBloc"
                        @click="goToUpdatDataBlocStatus(row.item)"
                        class=""
                        variant="warning"
                      >
                        <i class="fa-solid fa-eye-slash"></i>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </template>
    
    <script>
  import axios from "axios";
  import LeftNav from "../../LeftNav.vue";
  import TopNav from "../../TopNav.vue";
  import Footer from "../../Footer.vue";
  import Jumbottron from "../Shared/Jumbottron.vue";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import Swal from "sweetalert2";
  
  export default {
    components: {
      Jumbottron,
      LeftNav,
      TopNav,
      Footer,
    },
  
    data() {
      return {
        spinner: false,
        editor: ClassicEditor,
        loader: false,
  
        fields: [
          {
            key: "title",
            label: "Titre",
            sortable: true,
            sortDirection: "desc",
            class: "p-3",
          },
          {
            key: "default_chart_type",
            label: "Graphique",
            sortable: true,
            sortDirection: "desc",
            class: "p-3",
          },
          {
            key: "created_at",
            label: "Publié le",
            sortable: true,
            sortDirection: "desc",
            class: "p-3",
          },
          {
            key: "status",
            label: "Statut",
            sortable: true,
            class: "text-center p-3",
          },
          { key: "actions", label: "Actions", class: "text-center p-3" },
        ],
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Voir +" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
  
        infoModal: "",
        allDataBloc: [],
  
        modalTitle: null,
        loading: false,
        dataBlocTitle: null,

        date1: '',       // Première date sélectionnée
        date2: '',       // Deuxième date sélectionnée
        // apiData: [],     // Données renvoyées par l'API
        filteredData: [] // Données filtrées par les deux dates
        };
    },
  
    computed: {
        sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    },
  
    mounted() {
      this.getDataBloc();
    },
  
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

        filterDataByDate() {
            if (this.date1 && this.date2) {
            const startDate = new Date(this.date1);
            const endDate = new Date(this.date2);    
            // Mettre endDate à la fin de la journée
            endDate.setHours(23, 59, 59, 999);    

            this.filteredData = this.allDataBloc.filter(item => {
                const itemDate = new Date(item.created_at);
                return itemDate >= startDate && itemDate <= endDate;
            });
            } else {
                this.filteredData = this.allDataBloc; // Si les deux dates ne sont pas sélectionnées, afficher toutes les données
            }
            this.totalRows = this.filteredData.length;
        },

        reinializ(){
            this.date1 = ''
            this.date2 = ''
            this.getDataBloc()
        },
  
      async getDataBloc() {
        this.spinner = true;
        await axios.get("databloc").then((res) => {
          this.spinner = false;
          this.allDataBloc = res.data;

          this.filteredData = this.allDataBloc; // Initialiser avec toutes les données
            this.totalRows = this.filteredData.length;
        });
      },

      goToDeleteDataBloc(databloc) {
        Swal.fire({
          title: "Etes-vous sûrs ?",
          text: "Vous êtes sur le point de supprimer une dataBloc",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "#3BAE4C",
          confirmButtonText: "Oui, continuer",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non, annuler",
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`databloc/${databloc.id}`)
              .then(() => {
                Swal.fire({
                  title: "Supprimé !",
                  text: "Suppression effectuée avec succès",
                  icon: "success",
                });
                this.getDataBloc()
              })
              .catch(() => {
                Swal.fire({
                  title: "Erreur",
                  text: "Une erreur s'est produite, réessayer",
                  icon: "error",
                });
              });
          }
        });
      },
  
      goToReadDataBloc(data) {
        this.spinnerProd = true;
  
        this.$refs["show-modal-article"].show();
        this.modalTitle = data.title;
        this.product.id = data.id;
      },
  
      goToUpdatDataBlocStatus(data){
        Swal.fire({
            title: "Etes-vous sûrs ?",
            text: "Le statut de cette ligne sera modifié",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3BAE4C",
            confirmButtonText: "Poursuivre",
            cancelButtonColor: "#d33",
            cancelButtonText: "Non, annuler"
            }).then((result) => {
            if (result.isConfirmed) {
                if(data.status == "activ" || data.status == "active"){
                    axios.get(`status_update/dataBloc/${data.id}/innactiv`)
                    .then(()=>{
                        Swal.fire({
                            title: "Bravo !",
                            text: "Statut désactivé avec succès",
                            icon: "success"
                        });
                        this.getDataBloc()
                    })
                    .catch(()=>{
                        Swal.fire({
                            title: "Erreur",
                            text: "Une erreur s'est produite, réessayer",
                            icon: "error"
                        });
                    })
                }
                if(data.status == "innactiv"){
                    axios.get(`status_update/dataBloc/${data.id}/activ`)
                    .then(()=>{
                        Swal.fire({
                            title: "Bravo !",
                            text: "Statut activé avec succès",
                            icon: "success"
                        });
                        this.getDataBloc()
                    })
                    .catch(()=>{
                        Swal.fire({
                            title: "Erreur",
                            text: "Une erreur s'est produite, réessayer",
                            icon: "error"
                        });
                    })
                }
            }
        });
    },
    },
  };
  </script>

  <style scoped>
  .content-wrapper{
    font-family: 'Arial', sans-serif;
    font-weight: normal;
}
  </style>