<template>
  <div class="wrapper">
    <left-nav />
    <top-nav />

    <div class="content-wrapper">
      <jumbottron
        faIcon="fa-solid fa-plus"
        PageTitle="Création des datas"
      />

      <div class="content">
        <div class="container-fluid">

            <fieldset class="scheduler-border">
                <legend class="scheduler-border">DataProduct</legend>

                <b-card class="card card-primary card-outline">
                    <form @submit.prevent="sendData">
                        <div class="row">
                            <div class="">
                                <div class="form-group">
                                    <label for="name">Libellé</label> 
                                    <input type="text" class="form-control" id="name" v-model="payload.name" />
                                </div>
                            </div>
                            <div class="">
                                <div class="form-group">
                                    <label for="categories">Quelles catégories pour ce dataProduct ?</label>
                                    <!-- <select id="categories" v-model="payload.categories" multiple class="form-control">
                                        <option v-for="category in categoriesList" :key="category.id" :value="category.id">
                                            {{ category.name }}
                                        </option>
                                    </select> -->

                                    <b-card>
                                        <div class="row">
                                        <div class="col-sm-4" v-for="category in categoriesList" :key="category.id">
                                            <b-form-checkbox :value="category.id" :id="'category-' + category.id" v-model="payload.categories" name="check-button" switch>
                                                {{ category.name }}
                                            </b-form-checkbox>
                                            <!-- <input 
                                                type="checkbox" 
                                                :id="'category-' + category.id" 
                                                :value="category.id" 
                                                v-model="payload.categories"
                                            /> &nbsp;
                                            <label :for="'category-' + category.id">{{ category.name }}</label> -->
                                        </div>
                                    </div>
                                    </b-card>
                                    
                                </div>
                            </div>
                        </div>
                    

                    <div class="form-group">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" id="description" v-model="payload.description"/>
                    </div>
                    <!-- DataBloc -->
                        <fieldset class="scheduler-border">
                            <legend class="scheduler-border">DataBloc</legend>

                            <b-card class="card card-primary card-outline" v-for="(dataBloc, index) in payload.dataBlocs" :key="index">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <div class="form-group">
                                            <label :for="'title-' + index">Question {{ index+1 }}</label>
                                            <input type="text" class="form-control" :id="'title-' + index" v-model="dataBloc.dataBloc.title"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label :for="'default_chart_type-' + index">Type de graphique par défaut</label>
                                            <select class="form-control" :id="'default_chart_type-' + index" v-model="dataBloc.dataBloc.default_chart_type">
                                                <option value="" disabled selected>----</option>
                                                <option value="pie">Pie</option>
                                                <option value="line">Line</option>
                                                <option value="doughnut">Doughnut</option>
                                                <option value="bar">Bar</option>
                                            </select>
                                            <!-- <input type="text" class="form-control" :id="'default_chart_type-' + index" v-model="dataBloc.dataBloc.default_chart_type"/> -->
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <label :for="'status-' + index">Statut</label>
                                    <input type="text" disabled class="form-control" :id="'status-' + index" v-model="dataBloc.dataBloc.status"/>
                                </div>

                                <!-- DataBlocValue -->
                                <fieldset class="scheduler-border">
                                    <legend class="scheduler-border">DataBlocValue</legend>

                                    <b-card class="card-primary card-outline" v-for="(value, valueIndex) in dataBloc.dataBloc.dataBlocValues" :key="valueIndex">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label :for="'label-' + index + '-' + valueIndex">Réponse {{ index+1 }}</label>
                                                    <input type="text" class="form-control" :id="'label-' + index + '-' + valueIndex" v-model="value.label"/>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label :for="'data_value-' + index + '-' + valueIndex">Valeur</label>
                                                    <input type="number" class="form-control" :id="'data_value-' + index + '-' + valueIndex" v-model="value.data_value"/>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card>

                                    <div class="form-group">
                                        <button type="button" @click="addDataBlocValue(index)" class="btn btn-secondary">
                                            <i class="fa-solid fa-plus"></i> Ajouter une dataBlocValue
                                        </button>

                                        <button type="button" @click="removeDataBlocValue(index, valueIndex)" class="btn btn-warning float-right">
                                            <i class="fa-solid fa-eraser"></i> Retirer une dataBlocValue
                                        </button>
                                    </div>
                                </fieldset>
                            </b-card>

                            <button type="button" class="btn btn-primary mr-1" style="border:none" @click="addDataBloc(datablocs)">
                                <i class="fa-solid fa-plus"></i> Ajouter une DataBloc
                            </button>

                            <button type="button" class="btn btn-warning" style="border:none" @click="removeDataBloc(datablocs)">
                                <i class="fa-solid fa-eraser"></i> Retirer une DataBloc
                            </button>

                        </fieldset>

                    <div class="form-group float-right mt-2">
                        <b-spinner variant="success" v-if="loading" label="Spinning"></b-spinner>
                        <button class="btn text-bold btn-lg" style="background-color: #3BAE4C;color:#FFF" type="submit" v-if="!loading">
                            <i class="fa-solid fa-floppy-disk"></i> Enregistrer le formulaire
                        </button>
                    </div>
                    </form>
                </b-card>
            </fieldset>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
  <script>
import axios from "axios";
import LeftNav from "../../LeftNav.vue";
import TopNav from "../../TopNav.vue";
import Footer from "../../Footer.vue";
import Jumbottron from "../Shared/Jumbottron.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";

export default {
  components: {
    Jumbottron,
    LeftNav,
    TopNav,
    Footer,
  },

  data() {
    return {
      editor: ClassicEditor,
      loading: false,
      categoriesList: [],

      payload: {
        name: "",
        description: "",
        categories: [],
        dataBlocs: [
          {
            dataBloc: {
              title: "",
              default_chart_type: "",
              status: "activ",
              dataBlocValues: [
                {
                  label: "",
                  data_value: "",
                },
              ],
            },
          },
        ],
      },
    };
  },

  mounted() {
    this.getCategorie();
  },

  methods: {
    async getCategorie() {
      await axios.get("setup").then((res) => {
        this.categoriesList = res.data.activity_areas;
      });
    },

    addDataBlocValue(index) {
        this.payload.dataBlocs[index].dataBloc.dataBlocValues.push({
            label: "",
            data_value: ""
        })
    },

    removeDataBlocValue(dataBlocIndex, valueIndex){
        this.payload.dataBlocs[dataBlocIndex].dataBloc.dataBlocValues.splice(valueIndex, 1)
    },

    addDataBloc(item){
        this.payload.dataBlocs.push({
            dataBloc: {
              title: "",
              default_chart_type: "",
              status: "activ",
              dataBlocValues: [
                {
                  label: "",
                  data_value: "",
                },
              ],
            },
        })
    }, 

    removeDataBloc(index){
        this.payload.dataBlocs.splice(index, 1)
    },

    async sendData() {
        const payloadToSend = {
            ...this.payload,
            categories: this.payload.categories.join(', ')
        }
        this.loading = true
        let Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
        });
        if(
            !this.payload.name || 
            !this.payload.description || 
            !this.payload.categories ||
            !this.payload.dataBlocs
        ){
            Toast.fire({
                icon: "error",
                title: "Tous les champs sont requis",
            });
            this.loading = false
        }
        else{
            const send = await axios.post("data_product", payloadToSend)
            if(send){
                Toast.fire({
                    icon: "success",
                    title: "DataProduct ajouté avec succès ...",
                });
                this.loading = false
                
                this.payload = {
                name: "",
                description: "",
                categories: "",
                dataBlocs: [
                {
                    dataBloc: {
                    title: "",
                    default_chart_type: "",
                    status: "activ",
                    dataBlocValues: [
                        {
                        label: "",
                        data_value: "",
                        },
                    ],
                    },
                },
                ],
                }
            }
            else{
                Toast.fire({
                    icon: "error",
                    title: "Une erreur s'est produite, veuillez réessayer",
                });
                this.loading = false
            }
        }
    },
  },
};
</script>

<style scoped>
    fieldset.scheduler-border{
        border: 2px solid #3A77BC !important;
        padding: 0 10px 10px 10px;
        border-bottom: none;
    }

    legend.scheduler-border{
        width: auto !important;
        border:none;
        font-size: 14px;
        float: none;
        padding: inherit;
        color: #3BAE4C;
        font-weight: bold !important;
    }

    .content-wrapper{
        font-family: 'Arial', sans-serif;
        font-weight: normal;
    }
</style>