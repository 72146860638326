<template>
  <div class="wrapper">
    <left-nav />
    <top-nav />

    <div class="content-wrapper">
      <jumbottron
        faIcon="fa-solid fa-plus"
        PageTitle="Nouvel article de blog"
      />

      <div class="content">
        <div class="container-fluid">
          <b-card class="card card-primary card-outline">
            <form action="" @submit.prevent="handleSubmit()">
              <div class="row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label for="title">Titre de l'article *</label>
                    <input
                      id="title"
                      v-model="store.title"
                      type="text"
                      class="form-control"
                      autofocus
                      placeholder="Entrez le titre de l'article"
                    />
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="form-group">
                    <label for="file">Importez la couverture *</label>
                    <input
                      type="file"
                      @change="onFileSelected"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="desc">Brève description *</label>
                <textarea
                  id="desc"
                  cols="5"
                  v-model="store.desc"
                  rows="5"
                  placeholder="Description de l'article"
                  class="form-control"
                ></textarea>
              </div>

              <div class="form-group">
                <label for="content">Contenu de l'article *</label>
                <ckeditor
                  id="content"
                  :editor="editor"
                  v-model="store.content"
                ></ckeditor>
              </div>

              <div class="form-group float-right">
                <b-spinner
                  v-if="loading"
                  variant="primary"
                  type="grow"
                ></b-spinner>
                <strong v-if="loading" class="text-primary"
                  >votre article est en cours de publication</strong
                >
                <button v-if="!loading" type="submit" class="btn btn-success">
                  <i class="fa-solid fa-check"></i> Publier
                </button>
              </div>
            </form>
          </b-card>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import LeftNav from "../../LeftNav.vue";
import TopNav from "../../TopNav.vue";
import Footer from "../../Footer.vue";
import Jumbottron from "../Shared/Jumbottron.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";

export default {
  components: {
    Jumbottron,
    LeftNav,
    TopNav,
    Footer,
  },

  data() {
    return {
      editor: ClassicEditor,
      store: {
        title: "",
        desc: "",
        content: "",
        myFile: "",
      },
      loading: false,
      isStoreSaved: false,
      saveInterval: null,
    };
  },

  methods: {
    onFileSelected(e) {
      this.store.myFile = e.target.files[0];
    },

    saveDraft() {
      try {
        localStorage.setItem("articleDraft", JSON.stringify(this.store));
        this.isStoreSaved = true;
      } catch (error) {
        console.error("Error saving draft:", error);
      }
    },

    loadDraft() {
      try {
        const savedDraft = localStorage.getItem("articleDraft");
        if (savedDraft) {
          this.store = JSON.parse(savedDraft);
        }
      } catch (error) {
        console.error("Error loading draft:", error);
      }
    },

    handleSubmit() {
      let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      if (!this.store.myFile) {
        Toast.fire({
          icon: "error",
          title: "Une image de couverture est attendue",
        });
        this.loading = false;
      }
      if (!this.store.title || !this.store.desc || !this.store.content) {
        Toast.fire({
          icon: "error",
          title: "Tous les champs sont requis",
        });
        this.loading = false;
      } else {
        this.loading = true;
        const fd = new FormData();
        fd.append("file", this.store.myFile);
        fd.append("title", this.store.title);
        fd.append("description", this.store.desc);
        fd.append("content", this.store.content);
        fd.append("status", "innactiv");

        axios
          .post("news", fd)
          .then(() => {
            Toast.fire({
              icon: "success",
              title: "Article publié avec succès",
            });
            this.loading = false
            this.store.title = ""
            this.store.desc = ""
            this.store.content = ""
            this.store.myFile = ""
          })
          .catch(() => {
            Toast.fire({
              icon: "error",
              title: "Erreur. Vérifiez la qualité de l'image à enregistrer",
            });
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    // Charger le brouillon existant
    this.loadDraft();
    this.saveInterval = setInterval(this.saveDraft, 5000); // Sauvegarde toutes les 5 secondes
  },

  beforeDestroy() {
    clearInterval(this.saveInterval);
  },
};
</script>

<style scoped>
.wrapper {
  font-family: "Arial", sans-serif;
  font-weight: normal;
}
</style>